import { BaseViewModel } from '../BaseViewModel';
import ViewModelUtils from '../ViewModelUtils';

export class FooterViewModel extends BaseViewModel {
  readonly typeName = 'FooterViewModel';

  protected model?: Editor.Data.HeadersAndFootersCompoundModel;
  view?: HTMLElement;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);

    this.model = this.Data.headersAndFooters?.getSectionHeader(id);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);
    this.handleModelLoad = this.handleModelLoad.bind(this);
    this.model?.on('UPDATE', this.handleModelUpdate);
  }

  private handleModelUpdate(data: any | null) {
    this.render();
  }

  private handleModelLoad() {
    this.render();
  }

  async bindView(view: Editor.Visualizer.BaseView | null) {
    if (view) {
      this.removeAllChildren();
      this.view = view;
      //@ts-expect-error
      this.view.vm = this;
      await this.render();
    }
  }

  private async appendChild(viewModel: Editor.Visualizer.BlockViewModel) {
    if (this.view) {
      if (!viewModel.loaded) {
        await viewModel.awaitForEvent('LOADED');
      }
      viewModel.render();
      this.children.push(viewModel);
      const view = viewModel.getRootView();
      if (view) {
        this.view.appendChild(view);
        this.Visualizer.tabulator?.tabulate(viewModel);
        viewModel.triggerPendingEvent('RENDERED');
      }
    }
    viewModel.setParent(this);
  }

  async render() {
    if (this.view) {
      this.removeAllChildren();
      while (this.children.length) {
        this.children.shift()?.dispose();
      }
      let footer;
      if (ViewModelUtils.isPageViewModel(this.parent)) {
        if (this.parent.isFirstPage) {
          footer = this.model?.getPageFooter('FIRST_PAGE');
        } else if (this.parent.side() === 'VERSO') {
          footer = this.model?.getPageFooter('VERSO');
        } else if (this.parent.side() === 'RECTO') {
          footer = this.model?.getPageFooter('RECTO');
        }
      }

      const childNodes: string[] = footer?.childNodes || [];
      if (childNodes.length) {
        let vm: Editor.Visualizer.BlockViewModel | undefined;
        for (let index = 0; index < childNodes.length; index++) {
          vm = this.Visualizer.viewModelFactory?.get(
            childNodes[index],
            true,
          ) as Editor.Visualizer.BlockViewModel;
          if (vm) {
            await this.appendChild(vm);
          }
        }
      } else {
        this.view.style.visibility = 'hidden';
      }
    }
    return this.view;
  }

  dispose() {
    if (this.model) {
      // unbind model
      this.model.off('UPDATE', this.handleModelUpdate);
    }
    if (this.view && this.view.parentNode) {
      this.view.parentNode.removeChild(this.view);
    }
    this.Visualizer.viewModelFactory?.remove(this.id);
  }
}
