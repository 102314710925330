import { RealtimeObject } from '_common/services/Realtime';

const DEFAULT_PROPERTIES: Editor.Data.Sections.Properties = {
  t: 'c',
  p_o: 'P',
  sz: {
    w: 612,
    h: 792,
  },
  mar: {
    t: 72,
    r: 72,
    b: 72,
    l: 72,
    h: 36,
    f: 36,
    g: 0,
  },
};

const SECTION_PROP_MERGE: {
  [index in keyof Editor.Data.Sections.Properties]: (
    props: Editor.Data.Sections.Properties,
    prevData: Editor.Data.Sections.Data | undefined | null,
    currentData: Editor.Data.Sections.Data | undefined,
  ) => Editor.Data.Sections.Properties;
} = {
  p_o: (props, prevData, currentData) => {
    props.p_o = currentData?.p.p_o || DEFAULT_PROPERTIES.p_o;
    return props;
  },
  pi: (props, prevData, currentData) => {
    if (prevData?.p.pi?.fp || currentData?.p.pi?.fp) {
      props.pi = { fp: { ...prevData?.p.pi?.fp, ...currentData?.p.pi?.fp } };

      props.pi.fp = { ...prevData?.p.pi?.fp, ...currentData?.p.pi?.fp };
    }
    if (prevData?.p.pi?.ep || currentData?.p.pi?.ep) {
      if (!props.pi) {
        props.pi = {};
      }
      props.pi.ep = { ...prevData?.p.pi?.ep, ...currentData?.p.pi?.ep };
    }
    if (prevData?.p.pi?.op || currentData?.p.pi?.op) {
      if (!props.pi) {
        props.pi = {};
      }
      props.pi.op = { ...prevData?.p.pi?.op, ...currentData?.p.pi?.op };
    }
    return props;
  },
  sz: (props, prevData, currentData) => {
    if (prevData?.p.sz || currentData?.p.sz) {
      props.sz = { ...prevData?.p.sz, ...currentData?.p.sz };
    }
    return props;
  },
  mar: (props, prevData, currentData) => {
    if (prevData?.p.mar || currentData?.p.mar) {
      props.mar = { ...prevData?.p.mar, ...currentData?.p.mar };
    }
    return props;
  },
  cols: (props, prevData, currentData) => {
    if (prevData?.p.cols || currentData?.p.cols) {
      props.cols = { ...prevData?.p.cols, ...currentData?.p.cols };
    }
    return props;
  },
  t: (props, prevData, currentData) => {
    props.t = currentData?.p.t || DEFAULT_PROPERTIES.t;
    return props;
  },
  dfp: (props, prevData, currentData) => {
    props.dfp = currentData?.p.dfp;
    return props;
  },
  doe: (props, prevData, currentData) => {
    props.doe = currentData?.p.doe;
    return props;
  },
  pnf: (props, prevData, currentData) => {
    if (prevData?.p.pnf || currentData?.p.pnf) {
      props.pnf = { ...prevData?.p.pnf, ...currentData?.p.pnf };
    }
    return props;
  },
};

export class Section extends RealtimeObject<Editor.Data.Sections.Data> {
  protected previous?: Section;
  constructor(transport: Realtime.Transport.Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'sections');
    this.handlePreviousSectionUpdate = this.handlePreviousSectionUpdate.bind(this);
  }

  selectedData(): Editor.Data.Sections.Data | null {
    let data: Editor.Data.Sections.Data;

    if (this.loadedVersion) {
      data = this.loadedVersion.data ? this.loadedVersion.data : null;
    } else {
      data = this.model.data;
    }

    let properties: Editor.Data.Sections.Properties = { p_o: 'P', t: 'c' };

    let previousData = this.previous?.selectedData();

    const propKeys = Object.keys(SECTION_PROP_MERGE) as (keyof Editor.Data.Sections.Properties)[];

    for (let i = 0; i < propKeys.length; i++) {
      let propMerge = SECTION_PROP_MERGE[propKeys[i]];
      if (propMerge) {
        properties = propMerge(properties, previousData, data);
      }
    }

    data = {
      id: this.id,
      ...(data || {}),
      p: {
        ...properties,
      },
    };

    if (data != null) {
      return JSON.parse(JSON.stringify(data));
    }
    return null;
  }

  get previousSection() {
    return this.selectedData()?.ps;
  }

  get properties() {
    return this.selectedData()?.p;
  }

  get pageOrientation() {
    if (!this.selectedData()?.p.p_o) {
      return DEFAULT_PROPERTIES.p_o;
    }
    return this.selectedData()?.p.p_o;
  }

  get pageSize() {
    let data = this.selectedData();
    if (data?.p.sz) {
      return data.p.sz;
    }
    if (data?.p.p_o === 'L') {
      return {
        w: DEFAULT_PROPERTIES.sz?.h,
        h: DEFAULT_PROPERTIES.sz?.w,
      };
    }
    return DEFAULT_PROPERTIES.sz;
  }

  get pageMargins() {
    let data = this.selectedData();
    if (data?.p.mar) {
      return data.p.mar;
    }
    return DEFAULT_PROPERTIES.mar;
  }

  get pageNumberFormat() {
    let data = this.selectedData();
    return data?.p.pnf;
  }

  protected handlePreviousSectionUpdate() {
    this.emit('UPDATED', this.get(), []);
  }

  bindToPrevious(previous: Section) {
    if (this.previous !== previous) {
      this.previous?.off('LOADED', this.handlePreviousSectionUpdate);
      this.previous?.off('UPDATED', this.handlePreviousSectionUpdate);
      this.previous = previous;
      this.handlePreviousSectionUpdate();
      if (this.previous) {
        this.previous.on('LOADED', this.handlePreviousSectionUpdate);
        this.previous.on('UPDATED', this.handlePreviousSectionUpdate);
      }
    }
  }

  get sectionType() {
    return this.selectedData()?.p?.t;
  }

  static TYPE_NAME() {
    return 'SECTION';
  }

  static TYPE_COLLECTION() {
    return 'sections';
  }

  handleLoad(): void {}

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.emit('UPDATED', this.get(), ops, source);
    /* const length = ops.length;
    let op;
    let path;
    let updateOp = false;
    for (let i = 0; i < length; i++) {
      op = ops[i];
      path = op.p;
      if (path && path.includes('p')) {
        // eslint-disable-next-line no-continue
        updateOp = true;
      }
    } */
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
}
