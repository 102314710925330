import { BlockViewModel, PageViewModel, DocumentViewModel } from '../../..';
import { DocumentLayout } from '..';
import { SectionValuesUpdateType } from '../../LazyLoaderManager';
import { Logger } from '_common/services';
import { PageElement } from 'Editor/services/VisualizerManager/Views';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

type PagePosition = 'LEFT' | 'RIGHT';

type PageSide = 'VERSO' | 'RECTO';

class RenderStatus {
  protected stopped: boolean = false;
  protected canceled: boolean = false;
  protected rendering: boolean = false;
  processing?: Promise<any>;
  index: number = 0;

  get isRendering() {
    return this.rendering;
  }

  start(index: number) {
    this.stopped = false;
    this.canceled = false;
    this.rendering = true;
    this.index = index;
  }

  stop() {
    this.stopped = true;
    this.canceled = false;
    this.rendering = false;
    this.index = 0;
  }
}

class DeferRequest {
  promise?: Promise<any>;
  resolve?: (value: any) => void;
  reject?: () => void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    Object.freeze(this);
  }
}

class RenderTask<E extends () => void> {
  task: () => void;
  args: any;
  deferred: DeferRequest;

  constructor(task: E, ...args: Parameters<E>) {
    this.task = task;
    this.args = args;
    this.deferred = new DeferRequest();
  }

  get promise() {
    return this.deferred.promise;
  }
}

class RenderQueue {
  protected queue: any[] = [];
  protected tick: any = requestAnimationFrame;
  running: DeferRequest | null = null;
  paused: boolean = false;

  enqueue<E extends (...args: any) => void>(task: E, ...args: Parameters<E>) {
    if (!task) {
      throw new Error('No Task Provided');
    }
    const newTask = new RenderTask(task, ...args);
    this.queue.push(newTask);

    // Wait to start queue flush
    if (this.paused === false && !this.running) {
      this.run();
    }

    return newTask.promise;
  }

  clear() {
    this.queue = [];
    if (this.running) {
      this.running.resolve && this.running.resolve(null);
      this.running = null;
    }
  }

  stop() {
    this.queue = [];
    if (this.running) {
      this.running.resolve && this.running.resolve(null);
      this.running = null;
    }
    this.paused = true;
  }

  private dequeue() {
    let inwait: any;
    let task;
    let args;
    let result;

    if (this.queue.length && !this.paused) {
      inwait = this.queue.shift();
      task = inwait.task;
      args = inwait.args;
      if (task) {
        // console.log('args', args);

        result = task(...args);

        if (result && typeof result['then'] === 'function') {
          // Task is a function that returns a promise
          return result.then(
            () => {
              // logger.debug('TASK COMPLETED :');
              inwait.deferred.resolve.apply();
            },
            (error: Error) => {
              inwait.deferred.reject.apply(error);
            },
          );
        } else {
          // Task resolves immediately
          inwait.deferred.resolve.apply();
          return inwait.promise;
        }
      }
    } else {
      return Promise.resolve();
    }
  }

  private run() {
    if (!this.running) {
      this.running = new DeferRequest();
    }

    this.tick.call(window, () => {
      if (this.queue.length) {
        this.dequeue().then(this.run.bind(this));
      } else {
        this.running?.resolve && this.running?.resolve(null);
        this.running = null;
      }
    });

    // Unpause
    if (this.paused === true) {
      this.paused = false;
    }

    return this.running.promise;
  }
}

export class PageLayout extends DocumentLayout {
  protected pages: PageViewModel[];
  protected scheduledRefresh: any = {};
  protected renderstatus = new RenderStatus();
  protected queue: RenderQueue;

  private debug: boolean = true;

  constructor(
    Data: Editor.Data.API,
    Visualizer: Partial<Editor.Visualizer.State>,
    vm: DocumentViewModel,
  ) {
    super(Data, Visualizer, vm);
    this.pages = [];
    this.queue = new RenderQueue();
    this.layoutViewModelView = this.layoutViewModelView.bind(this);
    this.removeViewModelView = this.removeViewModelView.bind(this);
  }

  destroy(): void {
    this.removeAllChildren();
  }

  removeAllChildren() {
    while (this.pages.length) {
      this.pages.pop()?.dispose();
    }
    while (this.vm.view?.firstChild) {
      this.vm.view?.removeChild(this.vm.view?.firstChild);
    }
  }

  get numPages() {
    return this.pages.length;
  }

  getPageAtIndex(index: number) {
    return this.pages[index];
  }

  pagePosition(index: number): PagePosition {
    return index % 2 === 0 ? 'LEFT' : 'RIGHT';
  }

  pageSide(index: number): PageSide {
    return index % 2 === 0 ? 'VERSO' : 'RECTO';
  }

  handleChildChangedHeight(
    viewModel: BlockViewModel,
    view?: Editor.Visualizer.BaseView | null,
    difference?: number,
    scrollDiff?: number,
  ) {
    const pageElement = EditorDOMUtils.closest(
      viewModel.getRootView() || null,
      'PAGE-ELEMENT',
      this.vm.view,
    ) as PageElement;

    if (pageElement) {
      const index = this.vm.children.indexOf(viewModel);

      if (pageElement.vm?.hasOverflow() || pageElement.vm?.hasUnderflow()) {
        this.scheduleRefreshRender(index);
      } else if (viewModel.hasSplitViews()) {
        // TEMP: for now force rerender splited views, in the future check if the respective view as space for more text
        this.scheduleRefreshRender(index);
      }
    }

    // TODO: handle scroll
  }

  private removePage(pgIndex: number) {
    const pageView = this.pages[pgIndex].getRootView();
    pageView?.remove();
    this.pages.splice(pgIndex, 1);
  }

  private getPageFromBlock(blockIndex: number) {
    let vm = this.vm?.children.getAtIndex(blockIndex);
    if (vm) {
      let view = vm.getRootView();
      let pageView = EditorDOMUtils.closest(
        view || null,
        'PAGE-ELEMENT',
      ) as Editor.Visualizer.BaseView;
      if (pageView) {
        return pageView.vm;
      }
    }
    return null;
  }

  private removeBlocksViews(startIndex: number) {
    let viewModel;
    for (let index = this.vm?.children.length - 1; index >= startIndex; index--) {
      viewModel = this.vm?.children.getAtIndex(index) as BlockViewModel;
      this.removeViewModelView(viewModel);
    }
  }

  async scheduleRefreshRender(blockIndex: number) {
    if (this.debug) {
      Logger.trace('PageLayout scheduleRefreshRender', blockIndex);
    }

    if (this.renderstatus.isRendering) {
      if (this.renderstatus.index < blockIndex) {
        if (this.debug) {
          Logger.debug('Avoided refresh rendering ' + this.renderstatus.index + ' : ' + blockIndex);
        }
        // TODO check
        return this.queue.running?.promise;
      }

      this.renderstatus.stop();
      await this.renderstatus.processing;
    } else {
      this.renderstatus.stop();
    }

    return this.refreshRenderFromBlock(blockIndex);
  }

  async refreshRenderFromBlock(blockIndex: number) {
    if (blockIndex < 0) {
      blockIndex = 0;
    }
    this.renderstatus.start(blockIndex);
    let page = this.getPageFromBlock(blockIndex) as PageViewModel;
    if (!page) {
      return;
    }
    this.removeBlocksViews(blockIndex);
    let viewModel;
    for (
      let index = blockIndex;
      index <= this.vm?.children.length - 1 && this.renderstatus.isRendering;
      index++
    ) {
      viewModel = this.vm?.children.getAtIndex(index) as BlockViewModel;
      // this.queue.enqueue(this.layoutViewModelView, viewModel);
      this.renderstatus.processing = this.layoutViewModelView(viewModel);
      await this.renderstatus.processing;
    }
    this.renderstatus.stop();
    // this.queue.enqueue(() => this.renderstatus.stop());
    return this.queue.running?.promise;
  }

  private appendNewPage(sectionId?: string) {
    const page = this.Visualizer.viewModelFactory?.getPage(sectionId);
    if (page) {
      this.pages.push(page);
      page.setPageNumber(this.pages.length);

      let pageView = page.getRootView() as PageElement;

      if (pageView) {
        this.vm.view?.appendChild(pageView);
      }
    } else {
      throw new Error('Can not create a new page');
    }
  }

  private getLastPage(sectionId?: string) {
    if (this.pages.length === 0) {
      this.appendNewPage(sectionId);
    }
    return this.pages[this.pages.length - 1];
  }

  private getNextPage(pgIndex: number) {
    return this.pages[pgIndex + 1];
  }

  async updateBlockSection(viewModel: BlockViewModel) {
    const blockIndex = this.vm.children.indexOf(viewModel);
    if (blockIndex >= 0) {
      await this.scheduleRefreshRender(blockIndex);
    }
  }

  updateSectionsValues(delta: SectionValuesUpdateType) {
    const blocksToUpdate = Object.keys(delta);
    let blockId;
    let block: BlockViewModel;
    let blockIndex: number;
    for (let index = 0; index < blocksToUpdate.length; index++) {
      blockId = blocksToUpdate[index];
      block = this.vm.children.getById(blockId) as BlockViewModel;
      if (block && block.loaded) {
        block.updateSectionValue(delta[blockId]);
        blockIndex = this.vm.children.indexOf(block);
        if (blockIndex >= 0) {
          this.scheduleRefreshRender(blockIndex);
        }
      }
    }
  }

  updateSectionProperties(sectionId: string) {
    let block = this.vm.view?.querySelector(
      `section-element[section="${sectionId}"] > *:first-child`,
    ) as Editor.Elements.BaseViewElement;
    if (block && block.vm) {
      let blockIndex = this.vm.children.indexOf(block.vm);
      this.scheduleRefreshRender(blockIndex);
    }
  }

  updateParagraphStyle(styleId: string) {
    let block = this.vm.view?.querySelector(
      `*[data-style-id="${styleId}"]`,
    ) as Editor.Elements.BaseViewElement;
    if (block && block.vm) {
      let blockIndex = this.vm.children.indexOf(block.vm);
      this.scheduleRefreshRender(blockIndex);
    }
  }

  async appendChild(viewModel: BlockViewModel) {
    if (this.vm.view) {
      viewModel.setParent(this.vm);
      this.vm.children.push(viewModel);
      if (!viewModel.loaded) {
        await viewModel.awaitForEvent('LOADED');
      }

      this.renderstatus.start(this.vm.children.length - 1);
      this.renderstatus.processing = this.layoutViewModelView(viewModel);
      await this.renderstatus.processing;
      // this.queue.enqueue(this.layoutViewModelView, viewModel);

      this.renderstatus.stop();
    }
  }

  private async layoutViewModelView(viewModel: BlockViewModel) {
    this.renderstatus.index = this.vm.children.indexOf(viewModel);
    viewModel.render();
    let contents: any[] = [viewModel.getRootView()];
    let toInsert: any;

    let avoidPaginationProperties = false;

    let sectionId = this.Data.sections.getSectionOfBlock(viewModel.id);

    while (contents.length > 0 && this.renderstatus.isRendering) {
      toInsert = contents.shift();

      // pre process
      if (EditorDOMElements.isParagraphElement(toInsert)) {
        const inlinePageBreakBefore = toInsert.getPageBreakBefore();
        if (inlinePageBreakBefore != null) {
          // WARN: false case should do nothing
          if (inlinePageBreakBefore) {
            this.appendNewPage(sectionId);
          }
        } else if (toInsert.styleId) {
          const stylePageBreakBefore = this.Data.styles.getStylePageBreakBefore(toInsert.styleId);
          if (stylePageBreakBefore) {
            this.appendNewPage(sectionId);
          }
        }
      }

      let page = this.getLastPage(sectionId);
      let result = await page.layoutContents(toInsert, sectionId, avoidPaginationProperties);
      if (result.error) {
        throw result.error;
      }
      if (result.shouldBreak) {
        if (result.breakToken && result.breakToken.isSectionBreak()) {
          sectionId = result.breakToken.getNextSection();
        }

        if (result.overflow || result.breakToken?.needsBreak()) {
          this.appendNewPage(sectionId);
        }

        if (result.overflow) {
          contents.unshift(...(result.overflow || []));
          avoidPaginationProperties = true;
        }
      }
    }
  }

  private removeViewModelView(viewModel: BlockViewModel) {
    if (viewModel) {
      let pageVM;
      let views = viewModel.splitViews || [];
      let splittedView;
      let workingView;
      for (let j = views.length - 1; j >= 0; j--) {
        splittedView = views[j];
        workingView = splittedView.decoratorView || splittedView.view;
        let pageView = EditorDOMUtils.closest(
          workingView,
          'PAGE-ELEMENT',
        ) as Editor.Visualizer.BaseView;
        if (pageView) {
          pageVM = pageView.vm as PageViewModel;
        } else {
          logger.debug('page for splitted view not found!');
        }
        workingView.remove();
        if (pageVM && !pageVM?.hasContent()) {
          pageView?.remove();
          this.pages.splice(this.pages.indexOf(pageVM), 1);
        }
      }

      viewModel.splitViews = [];
    }
  }

  async replaceChildAt(viewModel: BlockViewModel, index: number) {
    viewModel.setParent(this.vm);
    let old = this.vm.children.splice(index, 1, [viewModel]);
    if (!viewModel.loaded) {
      await viewModel.awaitForEvent('LOADED');
    }
    await this.scheduleRefreshRender(index);
    if (old[0]) {
      old[0].dispose();
    }
  }

  async insertChildAt(viewModel: BlockViewModel, index: number) {
    if (this.vm.children && this.vm.children.length <= index) {
      await this.appendChild(viewModel);
    } else {
      viewModel.setParent(this.vm);
      this.vm.children.splice(index, 0, [viewModel]);
      if (!viewModel.loaded) {
        await viewModel.awaitForEvent('LOADED');
      }
      await this.scheduleRefreshRender(index);
    }
  }

  async removeChildAt(index: number, blockId: string) {
    let blockVM = this.vm.children.getById(blockId);
    if (blockVM) {
      const shouldRefresh = !(index === this.vm.children.length - 1);
      let [viewModel] = this.vm.children.splice(index, 1) as BlockViewModel[];
      if (viewModel && viewModel.id === blockId) {
        this.removeViewModelView(viewModel);
        viewModel.dispose();
      }
      if (shouldRefresh) {
        this.scheduleRefreshRender(index - 1);
      }
    }
  }
}
