import { NodeUtils } from 'Editor/services/DataManager';
import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import ReduxInterface from 'Editor/services/ReduxInterface';

export class RejectAllTrackedCommand extends Command {
  protected selectedTrackedActions: Editor.Edition.SelectedTrackedActions;
  protected forceAll: boolean = false;

  constructor(
    context: Editor.Edition.Context,
    selectedTrackedActions: Editor.Edition.SelectedTrackedActions,
    forceAll: boolean = false,
  ) {
    super(context);
    this.selectedTrackedActions = selectedTrackedActions;
    this.forceAll = forceAll;
  }

  async handleExec() {
    try {
      this.buildActionContext();

      if (
        !this.context.DataManager ||
        !this.context.DataManager.selection ||
        !this.context.selection?.modifiers ||
        !this.actionContext
      ) {
        throw new Error('Invalid Data');
      }

      const result = {
        selectionCollapsed: false,
      };

      const blockElements = JsonRange.filterElementDataFromRange(
        this.context.DataManager,
        this.actionContext.range,
        ['tracked-insert', 'tracked-delete'],
      );

      if (blockElements.length) {
        const closestTracked = blockElements[blockElements.length - 1];
        NodeUtils.getPreviousSibling(this.actionContext.baseData, closestTracked?.childPath);

        let previousSibling = NodeUtils.getPreviousSibling(
          this.actionContext.baseData,
          closestTracked?.childPath,
        );

        while (
          previousSibling &&
          previousSibling.data.properties?.element_reference ===
            closestTracked.childData.properties?.element_reference
        ) {
          previousSibling = NodeUtils.getPreviousSibling(
            this.actionContext.baseData,
            previousSibling.path,
          );
        }

        let path: Editor.Selection.Path | null = null;
        if (previousSibling) {
          const element = NodeUtils.getNextSibling(
            this.actionContext.baseData,
            previousSibling.path,
          );

          if (element) {
            path = element.path;
          }
        }

        if (!path) {
          path = ['childNodes', 0];
        }

        if (this.actionContext.baseData.id && path) {
          const jsonRange = new JsonRange({ b: this.actionContext.baseData.id, p: path });
          SelectionFixer.normalizeTextSelection(
            jsonRange,
            {
              suggestionMode: false,
            },
            this.context.DataManager,
          );

          // apply new selection
          this.applySelection(jsonRange);
        }
      }
      ReduxInterface.startEditorLoading();
      // reject tracked actions
      if (this.forceAll) {
        await this.context.DataManager?.suggestions?.rejectAllTrackedActions([]);
        result.selectionCollapsed = true;
      } else if (this.selectedTrackedActions.length) {
        await this.context.DataManager?.suggestions?.rejectAllTrackedActions(
          this.selectedTrackedActions.map((element) => element.ref),
        );
        result.selectionCollapsed = true;
      }
      ReduxInterface.stopEditorLoading();
    } catch (error) {
      logger.captureException(error);
      throw error;
    }
  }
}
