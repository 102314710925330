import { NodeUtils } from 'Editor/services/DataManager';
import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';

export class AcceptTrackedCommand extends Command {
  protected trackedActionId: string;

  constructor(context: Editor.Edition.Context, trackedActionId: string) {
    super(context);

    this.trackedActionId = trackedActionId;
  }

  async handleExec() {
    try {
      this.buildActionContext();

      if (
        !this.context.DataManager ||
        !this.context.DataManager.selection ||
        !this.context.selection?.modifiers ||
        !this.actionContext
      ) {
        throw new Error('Invalid Data');
      }

      const tracked = NodeUtils.closestOfTypeByPath(
        this.actionContext.baseData,
        this.actionContext.range.start.p,
        ['tracked-insert', 'tracked-delete'],
      );

      if (tracked && tracked.data.properties?.element_reference === this.trackedActionId) {
        NodeUtils.getPreviousSibling(this.actionContext.baseData, tracked?.path);

        let previousSibling = NodeUtils.getPreviousSibling(
          this.actionContext.baseData,
          tracked?.path,
        );

        while (
          previousSibling &&
          previousSibling.data.properties?.element_reference === this.trackedActionId
        ) {
          previousSibling = NodeUtils.getPreviousSibling(
            this.actionContext.baseData,
            previousSibling.path,
          );
        }

        let path: Editor.Selection.Path | null = null;
        if (previousSibling) {
          const element = NodeUtils.getNextSibling(
            this.actionContext.baseData,
            previousSibling.path,
          );

          if (element) {
            path = element.path;
          }
        }

        if (!path) {
          path = ['childNodes', 0];
        }

        if (this.actionContext.baseData.id && path) {
          const jsonRange = new JsonRange({ b: this.actionContext.baseData.id, p: path });
          SelectionFixer.normalizeTextSelection(
            jsonRange,
            {
              suggestionMode: false,
            },
            this.context.DataManager,
          );

          // apply new selection
          this.applySelection(jsonRange);
        }

        await this.context.DataManager?.suggestions?.acceptTrackedAction(this.trackedActionId);
      }
    } catch (error) {
      logger.captureException(error);
      throw error;
    }
  }
}
