import { MouseEventHandler, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip, Link, Icon, EmptyState } from 'dodoc-design-system';
import { ColumnProps, TableProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import dayjs from 'dayjs';

import { useDispatch, useSelector } from '_common/hooks';

import { setSelected } from '_common/components/Table/TableSlice';
import { getScopusCitationAuthors } from 'Editor/redux/CitationsSlice';

import { Table } from '_common/components/Table';
import Cell from '_common/components/Table/Cells';

import { ENTRIES } from '../CitationInformation/CitationInformation';
import { getSanitizedEntityName } from '../utils';

import styles from './CitationsList.module.scss';

type CitationsListProps = {
  citations: {
    list: ObjectId[];
    dict: Record<ObjectId, Citation>;
  };
  loading?: boolean;
  insertedCitations?: ObjectId[];
  libraryExportMode?: boolean;
  hasFilters?: boolean;
  // Handle click on import
  handleOnImportClick?: MouseEventHandler<HTMLButtonElement>;
};

type TableValue = Citation[];

const CitationsList = ({
  citations,
  insertedCitations,
  loading,
  libraryExportMode,
  hasFilters,
  handleOnImportClick,
}: CitationsListProps) => {
  const intl = useIntl();

  const selectedTab = useSelector((state) => state.tabMenu.citationsManager);
  const documentId = useSelector((state) => state.editor.status.documentId);
  const dispatch = useDispatch();

  const getAuthor = (citation: Citation | undefined) => {
    let author = '';

    if (citation?.entry === 'data_file') {
      return author;
    }

    if (citation?.author && entryHasField(citation, 'author')) {
      if (Array.isArray(citation.author)) {
        author = citation.author
          .map((author) => getSanitizedEntityName(author))
          .filter((authorName) => authorName)
          .join(', ');
      } else {
        author = getSanitizedEntityName(citation.author) || citation.author;
      }
    } else {
      author = intl.formatMessage({ id: 'editor.templatePreview.properties.authors.noAuthors' });
    }

    //@ts-expect-error CitationSchema issue: missing 'scopus_id'
    if (citation?.scopus_id) {
      author += ' (…)';
    }

    return author;
  };

  const renderEmptyView = () => {
    if (selectedTab === 'importReferences') {
      return (
        <div className={styles.emptyView}>
          <EmptyState
            size="large"
            title={intl.formatMessage({
              id: 'editor.modals.citations.noReferencesFound',
            })}
            onClick={handleOnImportClick}
            footer={<FormattedMessage id="editor.modals.citations.importFromFile" />}
            testId="no-references-found"
          >
            <FormattedMessage id="NO_REFERENCES_FOUND_MESSAGE" />
          </EmptyState>
        </div>
      );
    } else if (hasFilters) {
      return (
        <div className={styles.emptyView}>
          <EmptyState
            size="large"
            icon="NoSearchResults"
            title={intl.formatMessage({
              id: 'NO_RESULTS_FOUND',
            })}
            testId="no-results-found"
          >
            <FormattedMessage id="NO_RESULTS_FOUND_MESSAGE" />
          </EmptyState>
        </div>
      );
    }

    return (
      <div className={styles.emptyView}>
        <EmptyState
          size="large"
          title={intl.formatMessage({
            id: 'EMPTY_REFERENCE_LIBRARY',
          })}
          testId="empty-reference-library"
        >
          <FormattedMessage id="EMPTY_REFERENCE_LIBRARY_DESCRIPTION" />
        </EmptyState>
      </div>
    );
  };

  const columns = useMemo<ColumnProps[]>(() => {
    switch (selectedTab) {
      case 'documentLibrary': {
        if (!libraryExportMode) {
          return [
            {
              id: 'in_document',
              width: 48,
              flex: true,
            },
            {
              id: 'author',
              header: intl.formatMessage({ id: 'editor.modals.citations.fields.author' }),
              width: 120,
              flex: true,
            },
            {
              id: 'title',
              header: intl.formatMessage({ id: 'editor.modals.citations.fields.title' }),
              width: 272,
            },
            {
              id: 'published_in',
              header: intl.formatMessage({ id: 'global.publishedIn' }),
              width: 144,
              flex: true,
            },
            {
              id: 'year',
              header: intl.formatMessage({ id: 'global.year' }),
              width: 64,
              flex: true,
            },
            {
              id: 'added',
              header: intl.formatMessage({ id: 'editor.modals.citations.fields.added' }),
              width: 64,
              flex: true,
            },
          ];
        } else
          return [
            {
              id: 'author',
              header: intl.formatMessage({ id: 'editor.modals.citations.fields.author' }),
              width: 120,
              flex: true,
            },
            {
              id: 'title',
              header: intl.formatMessage({ id: 'editor.modals.citations.fields.title' }),
              width: 272,
            },
            {
              id: 'published_in',
              header: intl.formatMessage({ id: 'global.publishedIn' }),
              width: 144,
              flex: true,
            },
            {
              id: 'year',
              header: intl.formatMessage({ id: 'global.year' }),
              width: 64,
              flex: true,
            },
            {
              id: 'added',
              header: intl.formatMessage({ id: 'editor.modals.citations.fields.added' }),
              width: 64,
              flex: true,
            },
          ];
      }
      default: {
        return [
          {
            id: 'author',
            header: intl.formatMessage({ id: 'editor.modals.citations.fields.author' }),
            width: 120,
            flex: true,
          },
          {
            id: 'title',
            header: intl.formatMessage({ id: 'editor.modals.citations.fields.title' }),
            width: 272,
          },
          {
            id: 'published_in',
            header: intl.formatMessage({ id: 'global.publishedIn' }),
            width: 144,
          },
          {
            id: 'year',
            header: intl.formatMessage({ id: 'global.year' }),
            width: 64,
          },
        ];
      }
    }
  }, [libraryExportMode, selectedTab]);

  const entryHasField = (citation: Citation | undefined, field: Citation.Field) => {
    const entry = citation?.entry;
    if (
      entry &&
      entry in ENTRIES &&
      ENTRIES[entry as keyof typeof ENTRIES].some((supportedField) => supportedField === field)
    ) {
      return !!citation[field];
    }

    return false;
  };

  const handleRowClick: TableProps<TableValue>['onRowClick'] = ({ data }) => {
    const { id } = data;
    if (!libraryExportMode) {
      dispatch(setSelected({ [id]: true }));
    }
    if (selectedTab === 'searchReferences') {
      //@ts-expect-error
      if (citations.dict[id].scopus_id) {
        dispatch(
          getScopusCitationAuthors({
            documentId,
            citationId: id,
            //@ts-expect-error
            scopusId: citations.dict[id].scopus_id,
          }),
        );
      }
      dispatch(setSelected({ [id]: true }));
    }
  };

  const value = useMemo(() => {
    const list =
      selectedTab === 'documentLibrary'
        ? citations.list.sort((a, b) =>
            dayjs(citations.dict[b].time.creation).isAfter(citations.dict[a].time.creation)
              ? 1
              : -1,
          )
        : citations.list;
    return list.map((id) => {
      const citation = citations.dict[id];
      const author = getAuthor(citation);
      return {
        id: id,
        in_document: !libraryExportMode ? (
          <Cell testId={`${id}-in-document-column`}>
            {insertedCitations && insertedCitations.includes(id) && (
              <Tooltip
                content={intl.formatMessage({
                  id: 'editor.modals.citations.referenceCited',
                })}
                placement="top"
                testId={`${id}-in-document-tooltip`}
              >
                <Icon icon="Citations" size={32} />
              </Tooltip>
            )}
          </Cell>
        ) : null,
        author: (
          <Cell ellipsis testId={`${id}-author-column`}>
            <div className={`${author === 'No authors' && styles.noInfo}`}>{author}</div>
          </Cell>
        ),
        title: (
          <Cell testId={`${id}-title-column`}>
            {entryHasField(citation, 'url') ? (
              <div className={styles.linkCell}>
                <Tooltip
                  content={intl.formatMessage({ id: 'SEE_FULL_TEXT' })}
                  placement="top"
                  testId={`${{ id }}-see-full-text-tooltip`}
                >
                  <Link href={citation.url} size="medium" testId={`${{ id }}-see-full-text-link`}>
                    {citation.title || citation.url}
                  </Link>
                </Tooltip>
              </div>
            ) : (
              (entryHasField(citation, 'title') && (
                <div className={styles.cell}>{citation.title}</div>
              )) || (
                <div className={styles.noInfo}>{intl.formatMessage({ id: 'global.noTitle' })}</div>
              )
            )}
          </Cell>
        ),
        published_in: (
          <Cell ellipsis testId={`${id}-published-in-column`}>
            <>
              {(entryHasField(citation, 'source_title') && citation.source_title) ||
                (entryHasField(citation, 'publisher') && citation.publisher)}
            </>
          </Cell>
        ),
        year: (
          <Cell testId={`${id}-year-column`}>
            {entryHasField(citation, 'year') && citation.year}
          </Cell>
        ),
        added: (
          <Cell testId={`${id}-added-column`}>
            {dayjs(citation?.time?.creation).format('MMM DD')}
          </Cell>
        ),
      };
    });
  }, [citations]);

  return (
    <div className={styles.root}>
      <Table
        isLoading={loading ?? false}
        identity="references"
        columns={columns}
        value={value}
        renderFooter={() => null}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId="references"
        renderEmptyState={renderEmptyView}
        selectable={
          (selectedTab === 'documentLibrary' && !libraryExportMode) ||
          selectedTab === 'searchReferences'
            ? false
            : true
        }
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default CitationsList;
